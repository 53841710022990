.global-header {
    z-index: 1500;
}
html {
    position: relative;
    min-height: 100%;
}
body {
    margin-bottom:150px;
    /* bottom = footer height */
    // font-family: "Source Cambria",sans-serif;
}
footer {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 90px;
    width: 100%;
    overflow:hidden;
}

.navigation-search {
    border-bottom: 5px solid #046b99;
        border-bottom-color:rgb(4,107,153);
    position: relative;
}

.check-icon-checkbox, .check-icon-radio {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    font-size: 12px;
    border: 1px solid;
        border-top-color: currentcolor;
        border-right-color: currentcolor;
        border-bottom-color: currentcolor;
        border-left-color: currentcolor;
    border-color: #046b99;
    background-color: white;
}

// .main-navigation {
//     margin-right: 0px;

//   }

.first-level-link {
    margin-right: 12px;
}

.react-bootstrap-table-pagination {
    margin-top: 10px;
}
