.icon {
    font-size: "10vh"
}

.icon:hover,
.icon:focus {
    color: rgb(231, 175, 53);
    text-decoration: none;
    cursor: pointer;
}

.backgroundimg {
    background-image: url(/imgs/userImage.jpg);
    background-size: cover;
    background-position-y: 25%;
 }

 .App_disclaimer {
    bottom: 0;
    position: relative;
}

 .header-row{
    text-align: center;
    margin-top: 1vh;
 }

.cardTest {
    padding: 30px;
    border: 1px solid black;
}
.card-block {
    padding: .75rem;
}
.overlay {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 300px;
    background-color: rgba(27, 88, 146, 0.753);
}

.regBtn {
    background-color: rgba(27, 88, 146, 0.753);
    margin: 10px;
}
.regBtn:hover {
    background-color: rgba(27, 88, 146, 0.753);
}
.column {
    color: grey;
    text-decoration: none;
}

.column:hover,
.icon:focus {
    color: rgb(231, 175, 53);
    text-decoration: none;
    cursor: pointer;
}

.cardWithOutRoles{
    position: absolute; bottom: 0px; right: 15px;
}
.outline{
    border: 1px solid red;
} 

.continue{
    background-color: rgba(20, 65, 107, 0.753);
    margin:10px;
    color:white;
    font-size: 110%;
}
.continue:hover{
    color: rgb(231, 175, 53);
}

.pointer {cursor: pointer;}
.accountButton{
    text-align: center;
    // background-color: #E1F2F7;
    padding: 20px;
    
    width: auto; 
}
.accountButton:hover{
    background-color: rgb(210, 226, 230);
}
.card-block {
    padding: .50rem;
}
.navbtn{
    color: #3185a3;
    font-size: 18px;
}
.navbtn:hover{
    color: #3a404469;
    font-size: 18px;
    text-decoration: underline;
}
.routeStyling{
    margin: 0 25px;
}

.pendingNumber{
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: yellow;
    height: 25px;
    width: 25px;
    border-radius: 50%;
}

/* Table Styling */
.table-hover tbody tr:hover {
    
    background-color: rgb(159,199,218);
}
// .table thead th {
//     vertical-align: bottom;
//     border-bottom: 0px solid #dee2e6;
// }
// .table td, .table th {
//     padding: .75rem;
//     vertical-align: top;
//     border-top: 0px solid #dee2e6;
// }

.searchBorder{
    // box-shadow: 0 0 10px lightgray;
    // margin-top: 20px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 8px;
    border: 1px;
    border-style: solid;
    // background-color: #e1ecf7;
    border-color: black;
}

.AdvSearch__actions {
    display: flex;
    justify-content: space-between;
  }
  
  .AdvSearch__panel {
    margin-bottom: 125px;
  }

/* BUTTONS */
.btn-custom-teal-outline,  .btn-custom-teal-outline:active {
    border-color: #046b99;
    color:  #046b99;
}

.btn-custom-teal-outline:hover {
    background-color: #046b99;
    border-color: #046b99;
    color:  #ffffff;
}

.btn-custom-teal-outline:disabled{
    background-color: #ffffff;
    border-color: #046b99;
    color:  #046b99;
}


.btn-custom-teal-solid,  .btn-custom-teal-solid:active {
    border-color: #ffffff;
    color:  #ffffff;
    background-color: #046b99;
}

.btn-custom-teal-solid:hover {
    background-color: #ffffff ;
    border-color: #046b99;
    color:  #046b99;
}

.btn-custom-teal-solid.disabled, .btn-custom-teal-solid:disabled {
    border-color: #ffffff;
    color:  #ffffff;
    background-color: #046b99;
}



.btn-custom-red,  .btn-custom-red:active {
    background-color: #ffffff ;
    border-color: #c9302c;
    color:  #c9302c;
}

.btn-custom-red:hover {
    border-color: #ffffff;
    color:  #ffffff;
    background-color:#c9302c;
}

.headerTopPadding{
    padding-top: 130px;
}

.headerTopEnvPadding{
    padding-top: 170px;
}

.headerTopHomePagePadding{
    padding-top: 90px;
}

.headerTopHomePageEnvPadding{
    padding-top: 100px;
}

.branding .header-organization-banner img {
    padding-right: 15px;
}

.centered {
    left: 50%;
    text-align: center;
  }

  /* Loading Indicator */
.spinner {
    color: #103c5c;
    font-size: 20px;
    margin: 100px auto;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    // position: relative;
    // position: absolute;
    // top: 40%;
    // left: 50%;    
    position: fixed; top: 40%; left: 50%; /* bring your own prefixes */ transform: translate(-50%, -50%); 
    text-indent: -9999em;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    // transform: translateZ(0);
  }
  @-webkit-keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
  @keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }

  .spinner-fixed {
    color: #103c5c;
    font-size: 20px;
    margin: 100px auto;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    // position: relative;
    // position: absolute;
    // top: 40%;
    // left: 50%;    
    text-indent: -9999em;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    // transform: translateZ(0);
  }
  @-webkit-keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
  @keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }

/* MEDIA QUERIES */
@media screen and (max-width: 1199px) {
    .headerTopPadding{
        padding-top: 150px;
    }  

    .headerTopEnvPadding{
        padding-top: 200px;
    }
}

@media screen and (max-width: 991px) {
    .headerTopPadding{
        padding-top: 150px;
    }  

    .headerTopEnvPadding{
        padding-top: 200px;
    }
}

@media screen and (max-width: 767px) {
    .headerTopPadding{
        padding-top: 120px;
    }   

    .headerTopEnvPadding{
        padding-top: 160px;
    }
   
    .headerTopHomePageEnvPadding{
        padding-top: 130px;
    }
}

@media screen and (max-width: 575px) {
 
}

@media screen and (max-width: 443px) {
    .headerTopPadding{
        padding-top: 140px;
    }  

    .headerTopEnvPadding{
        padding-top: 190px;
    }
}

@media screen and (max-width: 351px) {
    .headerTopPadding{
        padding-top: 180px;
    }  

    .headerTopEnvPadding{
        padding-top: 220px;
    }
}


@media screen and (max-width: 269px) {
    .headerTopPadding{
        padding-top: 160px;
    }  

    .headerTopEnvPadding{
        padding-top: 210px;
    }
}

@media screen and (max-width: 262px) {
    .headerTopHomePagePadding{
        padding-top: 110px;
    }  

    .headerTopHomePageEnvPadding{
        padding-top: 160px;
    }
}

@media screen and (max-width: 230px) {
    .headerTopPadding{
        padding-top: 200px;
    }  

    .headerTopEnvPadding{
        padding-top: 240px;
    }
}

@media screen and (max-width: 193px) {
    .headerTopPadding{
        padding-top: 220px;
    }  

    .headerTopEnvPadding{
        padding-top: 270px;
    }
}